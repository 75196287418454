export default function Main() {
    return (
        <div>
            <h1>
                Warning
            </h1>
            <p>
                This is my first react app with nginx
            </p>
        </div>
    )
}